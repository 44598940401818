* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

li {
  font-weight: 600;
}

.my-swipper-portfolio {
  width: 450px;
  height: 400px;
  align-items: center;
  background-color: #f9f9f9;
  justify-content: center;
}

.my-swipper-slider-portfolio {
  text-align: center;
  font-size: 18px;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
}

.my-swiper-gallery {
  width: 450px;
  height: 562px;
  position: absolute;
  top: 50%;
  background-color: #171923;
}

.my-swiper-slider-gallery {
  background-position: center;
  background-size: cover;
  background-color: black;
}

.my-swiper-gallery img {
  display: block;
  height: 100%;
  width: 100%;
  background-color: black;
}

@media screen and (max-width: 768px) {
  .my-swipper-portfolio {
    width: 375px;
    height: 375px;
  }
}

@media screen and (max-width: 480px) {
  .my-swipper-portfolio {
    width: 300px;
    height: 400px;
  }
  .my-swiper-gallery {
    width: 350px;
    height: 437px;
  }
}
@media screen and (max-width: 400px) {
  .my-swipper-portfolio {
    width: 250px;
    height: 400px;
  }
  .my-swiper-gallery {
    width: 250px;
    height: 300px;
  }
  .g-recaptcha {
    transform: scale(0.71);
    -webkit-transform: scale(0.71);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: #ff7000 !important;
}

.swiper-pagination-bullet {
  background-color: black !important;
  opacity: 35% !important;
}
.swiper-pagination-bullet-active {
  background-color: #ff7000 !important;
  opacity: 100% !important;
}
